// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBTZYG41UUZpw4d6rYpknS6jjfjfNzML4g",
    authDomain: "gutsi-api.firebaseapp.com",
    databaseURL: "https://gutsi-api.firebaseio.com",
    projectId: "gutsi-api",
    storageBucket: "gutsi-api.appspot.com",
    messagingSenderId: "1026894521925",
    appId: "1:1026894521925:web:23995d6fc73b430998a52c",
    measurementId: "G-GW6YV4Q6CR",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
