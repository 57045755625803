export class GiftcardStats {
    activeAll?: any;
    activeValue?: number;
    activeCount?: number;
    redeemedAll?: any;
    redeemedValue?: number;
    redeemedCount?: number;
    all?: any;
    allValue?: number;
    allCount?: number;
}
