export const firebaseConfig = {
    apiKey: "AIzaSyBTZYG41UUZpw4d6rYpknS6jjfjfNzML4g",
    authDomain: "gutsi-api.firebaseapp.com",
    databaseURL: "https://gutsi-api.firebaseio.com",
    projectId: "gutsi-api",
    storageBucket: "gutsi-api.appspot.com",
    messagingSenderId: "1026894521925",
    appId: "1:1026894521925:web:23995d6fc73b430998a52c",
    measurementId: "G-GW6YV4Q6CR",
  };
  