import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "tab",
    loadChildren: './tabs/tabnav/tabnav.module#TabnavPageModule'
  },
  {
    path: '',
    redirectTo: 'tab/home',
    pathMatch: 'full'
  },
  {
    path: "login",
    loadChildren: () =>
      import("./shared/pages/login/login.module").then(
        (m) => m.LoginPageModule
      ),
  },  {
    path: 'scan-camera',
    loadChildren: () => import('./shared/pages/scan-camera/scan-camera.module').then( m => m.ScanCameraPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
